import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

import '@/assets/css/stylesheet.css'
import "vue-svg-inline-plugin/src/polyfills";

createApp(App).use(store).use(router).use(VueSvgInlinePlugin, {attributes: {data: [ "tabindex" ]}}).use(VueLazyload, {error: require('@/static/img/no_image.jpg'), attempt: 1}).mount('#app')