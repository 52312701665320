<template>
    <div class="home-layout">
        <header class="header">
            <img v-svg-inline class="header__logo" :src="require('@/assets/svg/logo.svg')" alt="mpland" />
            <div class="header__nav">
                <a href="#serviceFeatures" class="nav-item">Возможности</a>
                <a href="#pluginForChrome" class="nav-item">Плагин для Chrome</a>
                <a href="#topTwenty" class="nav-item">Топ 20</a>
                <a href="#popularProducts" class="nav-item">Популярные товары</a>
            </div>
            <div class="header__buttons">
                <MpButton purpleGradient name="Пора начинать!" @click="redirectToLogin"/>
                <MpButton name="Вход" @click="redirectToLogin"/>
            </div>
        </header>
        <div>
            <slot/>
        </div>
        <footer class="footer">
            <img v-svg-inline :src="require('@/assets/svg/vk.svg')" alt="vk" />
            <img v-svg-inline :src="require('@/assets/svg/telegram.svg')" alt="telegram" />
            <p>2023 mpland.ru | Бесплатный анализ конкурентов на Wildberries | Все права защищены.</p>
        </footer>
    </div>
</template>

<script>
import MpButton from '@/components/controls/MpButton.vue'

export default {
    name: 'HomeLayout',
    components:{
        MpButton
    },
    methods: {
        redirectToLogin() {
            window.location.href = `${process.env.VUE_APP_BASE_URL}/app/login`;
        }
    }
}
</script>

<style lang="scss" scoped>
.home-layout{
    padding-top: 57px;
    .header{
        margin-bottom: 20px;
        z-index: 1000;
        display: flex;
        align-items: center;
        &__logo{
            margin-right: 45px;
        }
        &__nav{
            margin-right: 17px;
            display: flex;
            align-items: center;
            gap: 17px;
            .nav-item{
                white-space: nowrap;
                text-decoration: none;
                color: #000;
                &:hover{
                    color: #c915c6;
                }
            }
        }
        &__buttons{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn{
                font-size: 19px;
                height: 31px;
            }
        }
    }

    .footer{
        border-top: 1px solid #c915c6;
        padding: 30px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }

    header, footer {
        padding: 0 calc(50% - 660px);
    }

    @media (max-width: 1360px){
        header, footer {
            padding: 0 calc(50% - 432px);
        }
    }

    @media (max-width: 920px){
        header, footer {
            padding: 0 calc(50% - 322px);
        }

        .header{
            margin-bottom: 50px;
            flex-direction: column;
            &__logo{
                margin-right: 0;
                margin-bottom: 12px;
            }
            &__nav{
                margin-right: 0;
                margin-bottom: 12px;
            }
            &__buttons{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 17px;
                .btn{
                    font-size: 19px;
                    height: 31px;
                }
            }
        }
    }

    @media (max-width: 720px){
        header, footer {
            padding: 0 24px;
        }

        .footer{
            img, svg{
                width: 16px;
                height: 16px;
            }
            p{
                font-size: 10px;
            }
        }
    }
}
</style>