import { createRouter, createWebHistory } from 'vue-router'
import HomeLayout from '@/layouts/HomeLayout.vue'

const routes = [

  // Dashboard - сводная информация по всем страницам
  {
    path: '/',
    name: 'home',
    meta: {
      layout: HomeLayout,
      title: 'Главная'
    },
    base: '',
    component: () => import('../views/HomeView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const DEFAULT_TITLE = 'Заголовок по умолчанию';
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
