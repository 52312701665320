<template>
    <button
        class="btn"
        :class="setClass"
        :type="type"
        :disabled="disabled"
        @click="callback($event)"
    >
        <slot v-if="image">
            <img
                class="btn__image"
                :src="image"
                :alt="image.replace(/^.*[\/\\]| \(\d+\)\.\w+$/g, '')"
            >
        </slot>
        <slot>
            {{ name }}
        </slot>
    </button>
</template>

<script>

export default {
  name: 'ButtonCustom',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: null
    },
    // Button types
    default: {
        type: Boolean,
        default: false
    },
    purpleGradient: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    setClass () {
      const typeBtn = this.default
        ? 'default'
        : this.purpleGradient
          ? 'purpleGradient'
          : null
      if (typeBtn === null) {
        return 'btn_' + 'default'
      } else {
          return 'btn_' + typeBtn
      }
    }
  },
  methods: {
    callback: function (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
    cursor: pointer;
    font-family: "Futura PT";
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    transition: ease-in 0.5s;
    background: transparent;
    color: #991096;
    min-width: fit-content;
    white-space: nowrap;

    &_default{
        color: #000;
        border: 1px solid #991096;
        &.active{
          background: linear-gradient(180deg, #FE1390 0%, #991096 100%);
          color: white !important;
        }
    }

    &_purpleGradient{
        background: linear-gradient(180deg, #FE1390 0%, #991096 100%);
        color: white;
    }
}
</style>