<template>
  <div id="app">
    <component :is="$route.meta.layout">
      <router-view />
    </component>
  </div>
</template>

<style>
html {
  scroll-behavior: smooth;
}
/* App */
#app {
  font-family: "Futura PT";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #000;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #000;
}
nav a.router-link-exact-active {
  color: #42b983;
}
/* Auth form */
.authForm {
  display: inline-block;
  max-width: 350px;
  text-align: left;
}
.authForm > div {
  margin-bottom: 15px;
}
.authForm .errors {
  color: red;
}

.searchForm {
  display: inline-block;
  max-width: 700px;
  min-width: 700px;
  text-align: center;
}

.page-title {
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: 800;
}

.page-description {
  margin-bottom: 25px;
  max-width: 1200px;
}
</style>
